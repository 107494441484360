import { useStaticQuery, graphql } from "gatsby"
export const useHoroscopoData = () => {



  const data = useStaticQuery(graphql`
  query MyQuery2 {
    allMarkdownRemark(
      filter: {
        fields: {
          collection: {eq: "horoscopo"}
        }
        
      }
      sort: { fields: [frontmatter___index], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
            mname
          }
          fields {
            slug
          }
        }
      }
    }
  }
  `)


  return data
}