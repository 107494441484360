import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logo from "../images/logo.png"

import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarMenu,
  Container
} from "bloomer"

export default class navbar extends Component {
  static propTypes = {
    siteTitle: PropTypes.string.isRequired,
  }
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }


  render() {

      const renderMenu = (items, dropdown=false )=> {
        return <div className={dropdown ? "navbar-dropdown":"navbar-end"}>
          { items.map(i => {
            if(i.menu){
              return <div key={i.link} className="navbar-item has-dropdown is-hoverable">
              <span 
                className="navbar-link navbar-item"
                // activeClassName="is-active"
                // to={i.link}
                >
                { i.title }
              </span>
              {renderMenu(i.menu, true)}
            </div>
            }else{
              return <Link 
                key={i.link}
                className="navbar-link is-arrowless"
                activeClassName="is-active"
                to={i.link}>
                { i.title }
              </Link>
            }
            
          })}
        </div>
      }



    const links = [   
      {
        "title": "Horóscopo",
        "link": "/horoscopo"
      },
      {
        "title": "Numerologia",
        "link": "/numerologia"
      },
      {
        "title": "Compatibilidade Amorosa",
        "link": "/astrologia/compatibilidade-amorosa"
      },
      {
        "title":"Anjos",
        "link":"/anjos"
      },
      {
        "title":"Oráculos",
        "link":"#",
        "menu":[
          {
            "title":"Runas",
            "link":"/oraculo/runas"
          },
          {
            "title":"Cristais",
            "link":"/oraculo/cristais"
          },
          {
            "title":"Tarot da Sorte",
            "link":"/oraculo/tarot-da-sorte"
          },
          {
            "title":"Tarot do Amor",
            "link":"/oraculo/tarot-do-amor"
          },
          {
            "title":"Baralho Cigano",
            "link":"/oraculo/baralho-cigano"
          },
          {
            "title":"Jogo de Tarot",
            "link":"/jogo-de-tarot"
          }
        ]
      }
    ];

    const items = renderMenu(links);
    
    // const items = links.map(function(item, i){
    //   return (
    //     <Link
    //       key={i}
    //       to={item.link}
    //       className="navbar-link nav-item is-arrowless"
    //       activeClassName="is-active"
    //     >
    //       {item.title}
    //     </Link>
    //   )
    // }); 


    return (
      <Navbar>
        <Container>
          {/* Site title link */}
          <NavbarBrand>
            <Link to="/" className="navbar-item">
              <img src={logo} alt={this.props.siteTitle} />
            </Link>
            <NavbarBurger
              isActive={this.state.isOpen}
              onClick={this.toggleCollapse}
            />
          </NavbarBrand>
          <NavbarMenu
            isActive={this.state.isOpen}
            onClick={this.toggleCollapse}
          >
            {/* Page nav links */}
            <NavbarEnd>
              {items}
              {/* { renderMenu(data) } */}
            </NavbarEnd>
          </NavbarMenu>
        </Container>
      </Navbar>
    )
  }
}
