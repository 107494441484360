import React from 'react';

import Icon from "@mdi/react";
import {
  mdiZodiacAquarius,
  mdiZodiacAries,
  mdiZodiacCancer,
  mdiZodiacCapricorn,
  mdiZodiacGemini,
  mdiZodiacLeo,
  mdiZodiacLibra,
  mdiZodiacPisces,
  mdiZodiacSagittarius,
  mdiZodiacScorpio,
  mdiZodiacTaurus,
  mdiZodiacVirgo
} from "@mdi/js";

function Sign(props) {
    const icons = {
        'aquario': mdiZodiacAquarius,
        'aries': mdiZodiacAries,
        'cancer': mdiZodiacCancer,
        'capricornio': mdiZodiacCapricorn,
        'gemeos': mdiZodiacGemini,
        'leao': mdiZodiacLeo,
        'libra': mdiZodiacLibra,
        'peixes': mdiZodiacPisces,
        'sagitario': mdiZodiacSagittarius,
        'escorpiao':mdiZodiacScorpio,
        'touro':mdiZodiacTaurus,
        'virgem': mdiZodiacVirgo
    };
    return (
        <Icon 
            path={icons[props.icon]}
            title={props.name}
            size={props.size?props.size:2}
        />    
    )
  }
export default Sign
