// src/components/googleAdsense.js

import React, { useEffect } from 'react'

export const Adsense = ({ path }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <ins 
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
      data-ad-client="ca-pub-7651311798887613"
      data-ad-slot="9475169885"
      data-ad-format="fluid"
      data-ad-layout-key="-6n+ep+q-5u+9i"
      data-adtest="on"
    />
  )
}