import React from "react"
import {Link} from "gatsby"
import Sign from '../components/sign'
import {
  Column,
  Columns,
  Card
} from "bloomer"  
import {useHoroscopoData} from '../hooks/use-horoscopo-data'


export default function HoroscopoBlock() {
  const data = useHoroscopoData()

  return (
    <>
    <Card className="horoscopo-block">
    <h3 className="is-full column card-header card-header-title mb-1">Horóscopo</h3>
    <Columns isMultiline isMobile className="has-text-centered">   
        {data.allMarkdownRemark.edges.map(({ node }) => (
            <Column 
            key={node.frontmatter.name}
            isSize='1/3'>
              <Link to={node.fields.slug}>
                <Sign 
                  icon={node.frontmatter.mname} 
                  title={node.frontmatter.name}
                  size={1}/>
                <h3 className="is-size-7">{node.frontmatter.name}</h3>
              </Link>
            </Column>
        ))}
    </Columns>
    </Card>
    </>
  )
}