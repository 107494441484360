import React from "react"
import {navigate} from "gatsby" 
import {
  Field,
  Control,
  Button,
  Select,
} from "bloomer"

import Icon from "@mdi/react";
import { mdiChevronRight } from '@mdi/js';

export default class Formulario extends React.Component {
  state = {
    day: "dia",
    month: "mês",
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { day, month } = this.state;

    if( (!isNaN(day)) && (!isNaN(month)) ){
      // function getZodiac2(month, day){
      //bound is zero indexed and returns the day of month where the boundary occurs
      //ie. bound[0] = 20; means January 20th is the boundary for a zodiac sign
      const bound = [20,19,20,20,20,21,22,22,21,22,21,21];
      //startMonth is zero indexed and returns the zodiac sign of the start of that month
      //ie. startMonth[0] = "Capricorn"; means start of January is Zodiac Sign "Capricorn"

      const startMonth = ["capricornio","aquario","peixes","aries","touro","gemeos",
                        "cancer","leao","virgem","libra","escorpiao","sagitario"];
      const monthIndex = month-1; //so we can use zero indexed arrays
      let signMonthIndex = 0;
      if (day <= bound[monthIndex]){ //it's start of month -- before or equal to bound date
         signMonthIndex = monthIndex;
      }else{ //it must be later than bound, we use the next month's startMonth
         signMonthIndex = (monthIndex+1) % 12; //mod 12 to loop around to January index.
      }
      //alert(`Welcome ${startMonth[signMonthIndex]}!`)

      navigate(`/horoscopo/${startMonth[signMonthIndex]}`);
    }
  }

  render() {
    return (
      <form className="card content" onSubmit={this.handleSubmit}>
        <header className="card-header card-header-title" >Qual é o meu signo do horóscopo</header>
        <Field hasAddons className="is-gapless columns card-content">       
          <Control className="column">
            <Select className="is-fullwidth" name="day" value={this.state.day} onChange={this.handleChange}>
              <option disabled defaultValue>dia</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
              <option>31</option>
            </Select>
          </Control>

          <Control className="column">
            <Select className="is-fullwidth" name="month" value={this.state.month} onChange={this.handleChange}>
              <option disabled defaultValue>mês</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Select>
          </Control>
          <Control className="column is-narrow">
            <Button className="is-fullwidth" type="submit" isColor='primary'>
            
            <Icon path={mdiChevronRight}
              title="Enviar"
              size={1} 
              color="#ffffff"
            />
            </Button>

          </Control>
        </Field>
      </form> 
    )
  }
}