import React from "react"
import {navigate} from "gatsby" 
import {
  Field,
  Label,
  Control,
  FieldBody,
  Button,
  Select,
} from "bloomer"

import Icon from "@mdi/react";
import { mdiChevronRight } from '@mdi/js';

export default class AnjosForm extends React.Component {
  state = {
    day: "dia",
    month: "mês",
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    let { day, month } = this.state;

    if( (!isNaN(day)) && (!isNaN(month)) ){
      var anjos =['vehuiah','jeliel','sitael','elemiah','mahasiah','lelahel','achaiah','cahechel','aziel','aladiah','laoviah','hahahiah','yesalel','mebahel','hariel','hekamiah','lauviah','caliel','leuviah','pahaliah','nelchael','leiaiel','melahel','haheuiah','nith-haiah','haaiah','ierathel','seheiah','reyel','omael','lecabel','vasariah','iehuiah','lehahiah','chavakiah','menadel','aniel','haamiah','rehael','ieiazel','hahahael','mikael','veuliah','yelaiah','sealiah','ariel','asaliah','mihael','vehuel','daniel','hahasiah','imamaiah','nanael','nithael','mebahiah','poiel','nemamiah','ieialel','harahel','mitzrael','umabel','iah-hel','anauel','mehiel','damabiah','manakel','ayel','habuhiah','rochel','yabamiah','haiaiel','mumiah','genios-da-humanidade'];
      // el 29 de febrero es igual al 28
      if (month === 2 && day === 29) day = 28;
      var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
      // número de día del año
      var dayOfYear = dayCount[month-1] + day;
      // empieza a contar a partir del 6 de enero y cada 73 días hay un angel
      var angelDayOfYear = (dayOfYear > 5)? dayOfYear-5 : dayOfYear+68;
      var angelId = (angelDayOfYear % 73);

      navigate(`/anjos/${anjos[angelId-1]}`);
    }
  }

  render() {
    return (
      <form className="card content" onSubmit={this.handleSubmit}>
        <header className="card-header card-header-title" >Descubra seu Anjo Protetor</header>
        {/* <Field hasAddons className="is-gapless columns">        */}
        <Field className="card-content">  
          <Label>Nasci em:</Label>
          <FieldBody className="is-gapless columns field has-addons">
         
            {/* <Control className="column"> */}
            <Control isExpanded className="column">
              <Select className="is-fullwidth" name="day" value={this.state.day} onChange={this.handleChange}>
                <option disabled defaultValue>dia</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
              </Select>
            </Control>

            {/* <Control className="column"> */}
            <Control isExpanded className="column">
              <Select className="is-fullwidth" name="month" value={this.state.month} onChange={this.handleChange}>
                <option disabled defaultValue>mês</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option> 
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </Select>
            </Control>
            <Control>
            {/* <Control className="column is-narrow"> */}
              <Button className="is-fullwidth" type="submit" isColor='primary'>
              
              <Icon path={mdiChevronRight}
                title="Enviar"
                size={1}
                color="#ffffff"
              />
              </Button>

            </Control>
            </FieldBody>     
        </Field>
      </form> 

    )
  }
}