/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import MainFooter from "./footer"
import Navbar from "./navbar"
import { Adsense } from "./adsense"

import {
  Section,
  Column,
} from "bloomer"
import { Columns } from "bloomer/lib/grid/Columns"

import HoroscopoBlock from "./horoscopo-block"
import Formulario from "./formulario"
import AnjosForm from "./anjosForm"

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
      <div className="site">
        <Navbar siteTitle={data.site.siteMetadata.title} />
         
        <main className="site-content container has-background-white">
        <Section>
        <Columns>
          <Column isSize="2/3">
          <Adsense path={path}></Adsense> 
          {children}
          <Adsense path={path}></Adsense>
          </Column>
          <Column isSize="1/3" className="mb-1">       
            <Formulario></Formulario>
            <HoroscopoBlock />
              {/* <Horoscope /> */}
            
            <AnjosForm></AnjosForm>
          </Column>
        </Columns>
        </Section>
        <MainFooter />
        </main>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
